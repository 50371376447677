// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
// import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import { DirectUpload } from "@rails/activestorage"
// import "channels"
require('flatpickr')

import LoaderGif from '../images/loader.gif';
import IconDocument from '../images/icon-document.svg';
import './uploads';

 import Swiper, { Navigation, Pagination, Autoplay } from 'swiper';
 // import Swiper styles
 import 'swiper/swiper.min.css';
 import 'swiper/modules/pagination/pagination.scss';
 import 'swiper/modules/navigation/navigation.scss';

import '../styles/application.scss';
import $ from 'jquery';

const images = require.context('../images', true)

Rails.start()
// Turbolinks.start()
ActiveStorage.start()

document.addEventListener('DOMContentLoaded', function() {
  const mobileButton = document.getElementById('mobileBars');
  const mobileMenu = document.querySelector('.pageHeader-nav');

  mobileButton.addEventListener('click', function(e) {
    e.preventDefault();
    mobileMenu.classList.toggle('open');
  });

  const closeMenuButton = document.getElementById('closeMenuButton');

  closeMenuButton.addEventListener('click', function(e) {
    e.preventDefault();
    mobileMenu.classList.remove('open');
  });
  const navOverlay = document.querySelector('.pageHeader-navOverlay');
  navOverlay.addEventListener('click', function(e) {
    mobileMenu.classList.remove('open');
  });

  const userMobileMenu = document.querySelector('.userLayout .userLayout-sidebarLabel');

  if (userMobileMenu !== null) {
    userMobileMenu.addEventListener('click', function(e) {
      e.preventDefault();
      $('.userLayout .userMenu').slideToggle(300);
    });
  }

});

document.addEventListener('DOMContentLoaded', function () {
  $('.flatpickr-input').flatpickr({
      locale: 'de',
      enableTime: true,
      dateFormat: "Y-m-d H:i",
      disableMobile: true,
      minDate: new Date().setUTCHours(0,0,0,0),
  });

  var validFromInput = $('#offer_valid_from');
  var validToInput = $('#offer_valid_to');

  const validFromPicker = validFromInput.flatpickr({
    locale: 'de',
    enableTime: false,
    dateFormat: "Y-m-d",
    disableMobile: false,
    maxDate: validToInput.val(),
    onChange: function(selectedDates, dateStr, instance) {
      validToPicker.set('minDate', dateStr)
    }
  });

  const validToPicker = $('#offer_valid_to').flatpickr({
    locale: 'de',
    enableTime: false,
    dateFormat: "Y-m-d",
    disableMobile: false,
    minDate: validFromInput.val(),
    onChange: function(selectedDates, dateStr, instance) {
      validFromPicker.set('maxDate', dateStr)
    }
  });
});


document.addEventListener('DOMContentLoaded', function () {
  const updatePrimaryCategoryId = () => {
    const selectedCategories = $('.form-group.category_list_wrapper input.form-control:checked').map((inx, item) => item.value).toArray();
    $('.registerPage-category').hide();
    selectedCategories.forEach(categoryId => {
      $(`.registerPage-category[data-category-id=${categoryId}]`).show();
    });
  }
  updatePrimaryCategoryId();
  $('.form-group.category_list_wrapper input.form-control').on('change', updatePrimaryCategoryId)

  $('.form-group.category_list_wrapper.company_category_ids input.form-control').on('click', function(e) {
    e.preventDefault();
    const button = document.getElementById('subscriptionButton')
    if (e.target.checked) {
      button.setAttribute('href', `/my-subscriptions/${e.target.value}/enable`);
    } else {
      button.setAttribute('href', `/my-subscriptions`);
    }
    button.click();
    return false;
  })

  const updateOpeningHours = () => {
    const appointmentByPhoneInput = $('form.accountForm #company_appointment_by_phone')
    const openingHoursList = $('#openingHoursList select');
    if (appointmentByPhoneInput.is(':checked')) {
      openingHoursList.prop('disabled', true);
    } else {
      openingHoursList.prop('disabled', false);
    }
  }
  $('form.accountForm #company_appointment_by_phone').on('change', updateOpeningHours);
  updateOpeningHours();
});

document.addEventListener('DOMContentLoaded', function () {
  $(document).on('submit', '.rateForm', function(e) {
    e.preventDefault();
    const form = $(this);
    const data = form.serialize();

    $.post(form.prop('action'), data, function(response) {
      if (response.html) {
        form.parents('.rateAnswer').replaceWith(response.html);
      }
    }, 'json')
  })
});

document.addEventListener('DOMContentLoaded', function () {
  $(document).on('submit', '.chatForm', function(e) {
    e.preventDefault();
    const form = $(this);
    const data = form.serialize();

    $.post(form.prop('action'), data, function(response) {
      if (response.html) {
        $('.chatPage-messages').append(response.html);
        form.find('#chat_message_body').val('');
        form.find('input[type=submit][disabled]').prop('disabled', false);
        form.find('#chat_message_body').focus();
      }
    }, 'json')
  });

  $(document).on('keyup', '.chatForm #chat_message_body', function(e) {
    if (e.key === 'Enter' && !e.shiftKey) {
      $('.chatForm').trigger('submit');
    }
  });
})


document.addEventListener('DOMContentLoaded', function () {
  const recalculateNotificationCost = function() {
    const data = {
      region_id: $('form#new_notification #notification_region_id').val(),
      gender: $('form#new_notification #notification_gender').val(),
      age_group: $('form#new_notification #notification_age_group').val(),
      birth_month: $('form#new_notification #notification_birth_month').val(),
    };
    $.get('/notifications/users_count', data, function(response) {
      $('.notificationsCalculator #users_counter').text(response.users_count);
      $('.notificationsCalculator #user_price').text(`${(1 + response.filters_count * 0.1).toFixed(2)} €`);
      $('.notificationsCalculator #total_price').text(`${((1 + response.filters_count * 0.1) * response.users_count).toFixed(2)} €`);
    });
  };

  $(
    'form#new_notification #notification_region_id, form#new_notification #notification_gender, form#new_notification #notification_age_group, form#new_notification #notification_birth_month'
  ).on('change', recalculateNotificationCost);
  if ($('form#new_notification').length > 0) {
    recalculateNotificationCost();
  }
})


document.addEventListener('DOMContentLoaded', function () {
  const swiper = new Swiper('.homeReviews', {
    slidesPerView: 1,
    loop: true,
    modules: [Pagination, Navigation, Autoplay],
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    },
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
    speed: 1500,
    autoplay: {
      delay: 10000,
      pauseOnMouseEnter: true
    },
  });
});
