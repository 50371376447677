import { DirectUpload } from "@rails/activestorage"
import LoaderGif from '../images/loader.gif';
import IconDocument from '../images/icon-document.svg';

class ImageUploader {
  constructor(file, url, input) {
    this.directUpload = new DirectUpload(file, url, this)
    this.input = input;
  }

  upload(file) {
    const { form } = this.input;
    const maxFiles = Number(form.dataset.maxFiles || '3')
    const previewsBox = document.querySelector('#images_preview');

    if (previewsBox.querySelectorAll('.image_box').length < maxFiles) {
      const container = document.createElement('div')
      container.classList.add('image_box')
      const image = document.createElement('img');
      image.setAttribute('src', LoaderGif);
      const removeButton = document.createElement('button');
      removeButton.classList.add('image_remove_btn')
      container.appendChild(image);
      container.appendChild(removeButton);
      previewsBox.appendChild(container);

      removeButton.addEventListener('click', () => {
        container.remove();
      })


      this.directUpload.create((error, blob) => {
        if (error) {
          container.remove();
        } else {
          image.setAttribute('src', `/rails/active_storage/blobs/${blob.signed_id}/${blob.filename}`);
          const input = document.createElement('input')
          input.setAttribute("type", "hidden");
          input.setAttribute("value", blob.signed_id);
          input.name = this.input.name
          container.appendChild(input)
        }
      })
    }
  }

  uploadLogo(file) {
    const { form } = this.input;
    const maxFiles = 1
    const previewsBox = document.querySelector('#logo_preview');

    if (previewsBox.querySelectorAll('.logo_box').length < maxFiles) {
      const container = document.createElement('div')
      container.classList.add('logo_box')
      const image = document.createElement('img');
      image.setAttribute('src', LoaderGif);
      const removeButton = document.createElement('button');
      removeButton.classList.add('logo_remove_btn')
      container.appendChild(image);
      container.appendChild(removeButton);
      previewsBox.appendChild(container);

      removeButton.addEventListener('click', () => {
        container.remove();
      })


      this.directUpload.create((error, blob) => {
        if (error) {
          container.remove();
        } else {
          image.setAttribute('src', `/rails/active_storage/blobs/${blob.signed_id}/${blob.filename}`);
          const input = document.createElement('input')
          input.setAttribute("type", "hidden");
          input.setAttribute("value", blob.signed_id);
          input.name = this.input.name
          container.appendChild(input)
        }
      })
    }
  }
  // directUploadWillStoreFileWithXHR(request) {
  //   request.upload.addEventListener("progress",
  //     event => this.directUploadDidProgress(event))
  // }

  // directUploadDidProgress(event) {
  //   console.log(event);
  //   // Use event.loaded and event.total to update the progress bar
  // }
}

const uploadImage = (file, input) => {
  const url = input.dataset.directUploadUrl
  const uploader = new ImageUploader(file, url, input);
  uploader.upload();
}

document.addEventListener('DOMContentLoaded', function () {
  const imageInput = document.querySelector('form.accountForm #company_images');
  if (imageInput !== null) {
    imageInput.addEventListener('change', (event) => {
      Array.from(imageInput.files).forEach(file => uploadImage(file, imageInput));
      imageInput.value = null;
    });
  }

  document.querySelectorAll('#images_preview button.image_remove_btn').forEach(node => {
    node.addEventListener('click', () => {
      const container = node.parentNode;
      container.remove();
    });
  });
});


const uploadLogo = (file, input) => {
  const url = input.dataset.directUploadUrl
  const uploader = new ImageUploader(file, url, input);
  uploader.uploadLogo();
}

document.addEventListener('DOMContentLoaded', function () {
  const imageInput = document.querySelector('form.accountForm #company_logo');
  if (imageInput !== null) {
    imageInput.addEventListener('change', (event) => {
      Array.from(imageInput.files).forEach(file => uploadLogo(file, imageInput));
      imageInput.value = null;
    });
  }

  document.querySelectorAll('#logo_preview button.logo_remove_btn').forEach(node => {
    node.addEventListener('click', () => {
      const container = node.parentNode;
      container.remove();
    });
  });
});



class DocumentUploader {
  constructor(file, url, input) {
    this.directUpload = new DirectUpload(file, url, this)
    this.input = input;
  }

  upload(file) {
    const { form } = this.input;
    const maxFiles = Number(form.dataset.maxFiles || '3')
    const previewsBox = document.querySelector('#documents_preview');

    if (previewsBox.querySelectorAll('.document_box').length < maxFiles) {
      const container = document.createElement('div')
      container.classList.add('document_box')
      const image = document.createElement('img');
      image.setAttribute('src', LoaderGif);
      const removeButton = document.createElement('button');
      removeButton.classList.add('document_remove_btn')
      container.appendChild(image);
      container.appendChild(removeButton);
      previewsBox.appendChild(container);

      removeButton.addEventListener('click', () => {
        container.remove();
      })


      this.directUpload.create((error, blob) => {
        if (error) {
          container.remove();
        } else {
          image.setAttribute('src', IconDocument)
          const hiddenField = document.createElement('input')
          hiddenField.setAttribute("type", "hidden");
          hiddenField.setAttribute("value", blob.signed_id);
          hiddenField.name = this.input.name
          container.appendChild(hiddenField)
        }
      })
    }
  }
}

const uploadDocument = (file, input) => {
  const url = input.dataset.directUploadUrl
  const attachmentName = input.dataset.directUploadAttachmentName

  const uploader = new DocumentUploader(file, url, input);
  uploader.upload();
}

document.addEventListener('DOMContentLoaded', function () {
  const documentsInput = document.querySelector('form.accountForm #company_documents');
  if (documentsInput !== null) {
    documentsInput.addEventListener('change', (event) => {
      Array.from(documentsInput.files).forEach(file => uploadDocument(file, documentsInput));
      documentsInput.value = null;
    });
  }

  document.querySelectorAll('#documents_preview button.document_remove_btn').forEach(node => {
    node.addEventListener('click', () => {
      const container = node.parentNode;
      container.remove();
    })
  });
});

